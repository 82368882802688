@import '../../node_modules/antd/dist/antd.less';


.highlighted-block {
  background-color: rgba(255, 255, 255, 0.84);
}

.sounded-block {
  background-color: rgba(58, 192, 146, 0.84);
}

@primary-color: #00A8A8;